import React, { useRef, useEffect } from "react";
import logo from "./Inconceivablyforged.svg"; // https://jakearchibald.github.io/svgomg/
import "./App.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

Splitting.add({
  by: "NOWORDS",
  depends: 0,
  key: "noword",
  split: (el) => {
    var results: HTMLElement[] = [];
    var text = el.innerText.split("");
    el.innerHTML = "";
    var F = document.createElement("span");
    F.className = "word";

    while (text.length) {
      let l = text.shift();
      const le = document.createElement("span");
      le.className = "char";
      if (l! === " ") le.className = "whitespace";
      le.textContent = l!;

      results.push(le);
      F.appendChild(le);
    }
    el.appendChild(F);
    return results;
  },
});

function App() {
  gsap.registerPlugin(ScrollTrigger);

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const element = ref.current!;
    Splitting();

    const fxPage3 = [
      ...element.querySelectorAll(".page3 .content__title[data-effect]"),
    ];

    const fxPage2 = [
      ...element.querySelectorAll(
        ".page2 .content__title[data-splitting][data-effect]"
      ),
    ];

    const fxPage1 = [
      ...element.querySelectorAll(
        ".page1 .content__title[data-splitting][data-effect]"
      ),
    ];

    fxPage3.forEach((title) => {
      const chars = title.querySelectorAll(".char");

      gsap.fromTo(
        chars,
        {
          "will-change": "opacity, transform",
          opacity: 0,
          scale: 0.6,
          rotationZ: () => gsap.utils.random(-20, 20),
        },
        {
          ease: "power4",
          opacity: 1,
          scale: 1,
          rotation: 0,
          stagger: 0.4,
          scrollTrigger: {
            trigger: title,
            start: "center+=20% bottom",
            end: "+=30%",
            scrub: true,
          },
        }
      );
    });

    fxPage2.forEach((title) => {
      // const words = title.querySelectorAll(".word");

      for (const word of [title]) {
        const chars = word.querySelectorAll(".char");

        gsap.fromTo(
          chars,
          {
            "will-change": "opacity, transform",
            x: (position, _, arr) => 150 * (position - arr.length / 2),
            opacity: 0,
          },
          {
            opacity: 1,
            ease: "power1.inOut",
            x: 0,
            stagger: {
              grid: "auto",
              from: "center",
            },
            scrollTrigger: {
              trigger: word,
              start: "center+=20% bottom",
              end: "+=30%",
              scrub: true,
            },
          }
        );
      }
    });

    fxPage1.forEach((title) => {
      const chars = title.querySelectorAll(".char");

      chars.forEach((char) => gsap.set(char.parentNode, { perspective: 1000 }));

      gsap.fromTo(
        chars,
        {
          "will-change": "opacity, transform",
          opacity: 0.2,
          z: -800,
        },
        {
          ease: "back.out(1.2)",
          opacity: 1,
          z: 0,
          stagger: 0.04,
          scrollTrigger: {
            trigger: title,
            start: "center+=20% bottom",
            end: "+=30%",
            scrub: true,
          },
        }
      );
    });
  }, []);
  return (
    <div className="main" ref={ref}>
      <header className="App-header">
        <img
          src={logo}
          className="App-logo"
          alt="logo for Inconceivably Forged"
        />
        <p>"Forging ahead with the inconceivable."</p>
        <div className="scroll-down">
          Scroll down<div className="arrow"></div>
        </div>
      </header>
      <div className="page page1">
        <div className="content">
          <h2 className="content__title" data-splitting="" data-effect>
            <span className="font-16 font-upper">Coruscation</span>
            <span className="font-16 font-upper font-sub font-7">Void</span>
          </h2>
        </div>
        <div className="content">
          <p>
            Get ready for an intense space adventure in "The Coruscation Void"!
          </p>
          <p>
            Take control of a powerful spaceship and blast your way through
            waves of neon-colored enemy ships in this top-down twin-stick
            shooter game.
          </p>
          <p>
            With a variety of ship types to choose from and a range of
            upgradable weapons and abilities, you'll need to use strategy and
            quick reflexes to defeat the Coruscation Syndicate and restore peace
            to the galaxy.
          </p>
          <p>
            Featuring a visually stunning minimalist art style and an original
            electronic soundtrack, "The Coruscation Void" is an unforgettable
            journey through the depths of space.
          </p>
          {/* <p> Download now on iOS, Android, and
          Steam and join the fight against the Coruscation Syndicate!
        </p> */}
        </div>
        <div className="scroll-down">
          Scroll down<div className="arrow"></div>
        </div>
      </div>
      <div className="page page2">
        <div className="content">
          <h2
            className="content__title content__title--small"
            data-splitting=""
            data-effect
          >
            <span>Management</span>
            <span className="font-6">Hell</span>
          </h2>
        </div>
        <div className="content">
          <p>
            Introducing the ultimate mobile management game for the devil in you
            - Management Hell!
          </p>
          <p>
            Step into the shoes of Satan himself and take on the challenge of
            managing the depths of hell. In this thrilling game, you'll be
            tasked with recruiting demons, managing resources, and making sure
            the souls of the damned are tormented for eternity.
          </p>
          <p>
            With stunning graphics and engaging gameplay, Hell Manager is the
            perfect mobile game for anyone who loves strategy and simulation
            games. As you progress through the game, you'll unlock new abilities
            and earn rewards that will help you build an even more powerful army
            of demons and take over the underworld.
          </p>
          <p>
            But be warned, there are challenges that will test your skills as a
            manager of the underworld. You'll need to balance your resources
            carefully to ensure your demons have enough energy to keep
            tormenting souls, while also keeping them happy and motivated. And
            as you climb the ranks and become more powerful, you'll face even
            greater challenges from rival demons and other entities.
          </p>
          {/* <p> So what are you waiting for? Join the ranks of Satan and take control of
            the underworld in Hell Manager! Download now from the App Store or
            Google Play.
          </p> */}
        </div>
        <div className="scroll-down">
          Scroll down<div className="arrow"></div>
        </div>
      </div>
      <div className="page page3">
        <div className="content">
          <h2 className="content__title" data-effect>
            <span
              className="font-upper font-sub font-7"
              data-splitting="NOWORDS"
            >
              gone to the
            </span>
            <span data-splitting="" className="font-upper font-7">
              races
            </span>
          </h2>
        </div>
        <div className="content">
          <p>
            Get ready to enter the exciting world of horse racing in "Gone to
            the Races", the ultimate mobile game designed for girls who love
            horses! Manage your own stable, customize your horses, and
            participate in thrilling racing game modes, including endless
            runner, side scroller, and multiplayer racing. Care for your horses,
            train them to perfection, and watch them win races and earn rewards.
            With vibrant graphics, intuitive gameplay, and a freemium model
            allowing for in-app purchases, "Gone to the Races" is the perfect
            game for any young girl who dreams of becoming a successful horse
            trainer and racer. Download now and experience the thrill of the
            racetrack!
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
